.suspense-component {
  display: flex;
  width: 100%;

  .app-container {
    width: 100%;

    &.logged-in-admin {
      height: 100%;
      width: 100%;
      padding: 2em 3% 2em 3%;
      min-width: 800px;

      @media screen and (max-width: 768px) {
        min-height: 100vh;
      }
    }

    &.logged-in-sys-admin {
      height: 100%;
      width: 100%;
      padding: 2em 3% 2em 3%;
      min-width: 800px;

      @media screen and (max-width: 768px) {
        min-height: 100vh;
      }
    }

    &.logged-in-user {
      height: 100%;
      width: 100%;
      padding: 2em 3% 2em 3%;
      min-width: 800px;

      @media screen and (max-width: 768px) {
        min-height: 100vh;
      }
    }

    &.logged-in-manager {
      height: 100%;
      width: 100%;
      padding: 2em 3% 2em 3%;
      min-width: 800px;

      @media screen and (max-width: 768px) {
        min-height: 100vh;
      }
    }
  }
}
