@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 220, 8.9%, 46.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;

    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

.theme-custom {
  /* Name: custom color palette
     Author: Ilias Ism
     URL: https://gradient.page */

  /* CSS: .bg-gradient { background: var(--gradient) } */
  --gradient: #e0eafc;

  --background: 232 53.3% 7.44%;
  --foreground: 232 8.2% 99.65%;

  --muted: 232 41% 27.900000000000002%;
  --muted-foreground: 232 8.2% 59.3%;

  --popover: 232 48.6% 12.09%;
  --popover-foreground: 232 8.2% 99.65%;

  --card: 232 48.6% 12.09%;
  --card-foreground: 232 8.2% 99.65%;

  --border: 232 41% 27.900000000000002%;
  --input: 232 41% 27.900000000000002%;

  --primary: 232 82% 93%;
  --primary-foreground: 232 8.2% 9.3%;

  --secondary: 232 41% 27.900000000000002%;
  --secondary-foreground: 232 8.2% 99.65%;

  --accent: 232 41% 27.900000000000002%;
  --accent-foreground: 232 8.2% 99.65%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 232 8.2% 99.65%;

  --ring: 232 82% 93%;
}
